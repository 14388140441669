import React, { useEffect, useMemo, useRef, useState } from "react";
import MaterialTable, { Query, QueryResult } from "material-table";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  Checkbox,
  createMuiTheme,
  FormControlLabel,
  MuiThemeProvider,
} from "@material-ui/core";
import moment from "moment";
import DataTablePagination, {
  DataTableInnerPaginationProps,
} from "./components/DataTablePagination";
import dataTableIcons from "./utils/dataTableIcons";
import tableOptions from "./utils/tableOptions";
import Column from "./models/Column";
import localization from "./utils/localization";
import userLocalization from "./utils/localizationUser";

import { useLocation } from 'react-router-dom';
import DataTableMoreActions, {
  DataTableRowAction,
} from "./components/DataTableMoreActions";
import mapQueryParams from "./utils/mapQueryParams";
import styles from "./DataTable.module.scss";
import { DateRangeValue } from "../DateRangePicker";
import useDataFetch from "./utils/useDataFetch";
import DataTableToolbar, {
  DataTableToolbarOptions,
} from "./components/DataTableToolbar";
import { DEFAULT_DATE_FORMAT } from "../../util/dates/DateFormats";
import DateRangeFilter from "./components/DateRangeFilter";
import { title } from "process";
import CityFilter from "./components/VerifyCityFilter";
import VerifyCityFilter from "./components/VerifyCityFilter";


export interface DataTableQueryParams {
  readonly pageNumber: number;
  readonly orderBy?: string | number | symbol;
  readonly order: "asc" | "desc";
  readonly searchTerm: string;
  readonly [key: string]: string | string[] | number | symbol | undefined | boolean;

}

interface RemoteResponse<T> {
  readonly data: T[];
  readonly totalPages: number;
}

interface DataTableProps<T extends {}> {
  readonly columns: Column<T>[];
  readonly fetchRemoteData?: (
    params: DataTableQueryParams
  ) => Promise<RemoteResponse<T>>;
 
  readonly fetchData?: () => Promise<T[]>;
  readonly toolBarOptions?: DataTableToolbarOptions;
  readonly filters?: Partial<
    {
      [property in keyof T]: (vars: {
        showArchived: boolean;
      }) => { [key: string]: string | number };
    }
  >;
  readonly archiveFilter?: {
    readonly enabled: boolean;
    readonly title?: string;
    readonly queryParam?: string;
  };
  readonly favouriteFilter?: {
    readonly enabled: boolean;
    readonly title?: string;
    readonly queryParam?: string;
  };


  // readonly favouriteFilter?: {
  //   readonly enabled: boolean;
  //   readonly title?: string;
  //   readonly queryParam?: string;
  // };
  readonly rowActions?: (data?: T) => DataTableRowAction<T>[];
  readonly additionalFilters?: { [key: string]: any };
}

const DataTable = <T extends {}>({
  columns,
  fetchRemoteData,
  fetchData,
  filters = {},
  archiveFilter,
  favouriteFilter,
  rowActions = () => [],
  toolBarOptions,
  additionalFilters,
}: DataTableProps<T>) => {
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState(false);
  const [actionMenu, setActionMenu] = useState<{
    target: string;
    rowData: T | T[];
  } | null>(null);

  const {
    enabled: enableArchiveFilter,
    title: archiveTitle = "archived",
    queryParam: archivedParam = "showArchived",
  } = archiveFilter ?? {
    enabled: false,
  };
  const {
    enabled: enableFavouriteFilter,
    title: FavouriteTitle = "Favourites",
    queryParam: favouriteParam = "showFavourited",
  } = favouriteFilter ?? {
    enabled: false,
  };
  const [showFavourited, setShowFavourited] = useState<boolean>(false);
  const [favouritedActivated, setFavouritedActivated] = useState<boolean>(false); // Track first activation
  const [page, setPage] = useState<number>(0); // Track current page
  const [showArchived, setShowArchived] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRangeValue>([null, null]);
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);
  const { isLoading, data, refresh: refreshDataFetch } = useDataFetch(
    fetchData
  );

  const tableRef = useRef<{ onQueryChange: () => void }>(null);

  const handleSetFavourited = (checked: boolean) => {
    if (checked && !favouritedActivated) {
      setFavouritedActivated(true);
      setPage(0);
    }
    setShowFavourited(checked);
  };

  useEffect(() => {
    if (initialFetchComplete) {
      // eslint-disable-next-line no-unused-expressions

      tableRef.current?.onQueryChange();
    }
  }, [enableFavouriteFilter?showFavourited:showArchived, dateRange, additionalFilters]);



  const fetch = (query: Query<T>): Promise<QueryResult<T>> => {
    if (query.search.length > 0) {
      setSearchTerm(true);

    }
    if (!fetchRemoteData) throw new Error("Missing remote fetching function");
    const pageToFetch = favouritedActivated ? 0 : query.page;
      return fetchRemoteData(
      mapQueryParams(
        {...query, page:pageToFetch},
        { [enableFavouriteFilter?favouriteParam:archivedParam]: enableFavouriteFilter?showFavourited:showArchived, dateRange },
        additionalFilters
      )
     
    ).then(
      (response): QueryResult<any> => {
        setTotalPages(response.totalPages);
        setInitialFetchComplete(true);
        setFavouritedActivated(false);
        return {
          data: response.data,
          page: pageToFetch,
          totalCount: totalPages * 10 + 100,
        };
      }
    );
  };



  const columnsWithFilters: Column<T>[] = useMemo(
    () =>
      columns.map((column) => {
        const columnFilter = filters[column.field];
        return {
          ...column,
          filterComponent: column.type === "date" ? DateRangeFilter : column.field === "City" ? VerifyCityFilter : undefined,
          render: (() => {
            if (column.render) {
              return column.render;
            }
            // switch (column.type) {
            //   case "date":
            //     return (rowData: T) =>
            //       moment(rowData[column.field]).format(DEFAULT_DATE_FORMAT);
            //   default:
            //     return undefined;
            // }
          })(),
          filtering: column.filtering ?? !!columnFilter,
          lookup: columnFilter?.({ showArchived: showArchived ?? false }),
        };
      }),
    [columns, filters, showArchived]
  );

  const location = useLocation();

  const isRemote = !fetchData;


  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#EA7600",
      },
    },
  });
  return (
    <div className={styles.table}>
      {enableArchiveFilter && (
        <FormControlLabel
          control={
            <Checkbox
              checked={showArchived ?? false}
              onChange={(event, checked) => {
                setShowArchived(checked);
              }}
            />
          }
          label={`Show ${archiveTitle}`}
        />
      )}

      {enableFavouriteFilter && (
        <FormControlLabel
          control={
            <Checkbox
              checked={showFavourited ?? false}
              onChange={(event, checked) => {
                handleSetFavourited(checked);
              }}
            />
          }
          label={`Show ${FavouriteTitle}`}
        />
      )}




      <MuiThemeProvider theme={theme}>
        <MaterialTable
          columns={columnsWithFilters}
          tableRef={tableRef}
          data={isRemote ? fetch : data}

          isLoading={isLoading}
          actions={
            rowActions?.length > 0
              ? [
                {
                  icon: MoreVertIcon,
                  tooltip: "More",
                  onClick: (event, rowData) => {
                    setActionMenu({ target: event.currentTarget, rowData });
                  },
                },
              ]
              : []
          }
          localization={(location.pathname.indexOf("user") > 0 && searchTerm == false) ? userLocalization : localization}
          icons={dataTableIcons}
          options={{
            ...tableOptions,
            filtering: columnsWithFilters.some((column) => column.filtering
            ),
          }}
          components={{
            Toolbar: (props) => (
              <DataTableToolbar
                {...props}
                options={toolBarOptions}
                setDateRange={setDateRange}
                dateRange={dateRange}
              />
            ),
            Pagination: (pageProps: DataTableInnerPaginationProps) => (
              <DataTablePagination
                columns={columns.length}
                innerProps={pageProps}
                totalPages={
                  isRemote
                    ? totalPages
                    : (() => {
                      const pages = pageProps.count / 10;
                      return Number.isInteger(pages)
                        ? pages
                        : Math.floor(pages) + 1;
                    })()
                }
              />
            ),
          }}
        />
      </MuiThemeProvider>
      <DataTableMoreActions<T>
        actions={rowActions}
        onClose={() => setActionMenu(null)}
        target={actionMenu?.target}
        rowData={actionMenu?.rowData}
        refresh={() =>
          isRemote ? tableRef.current?.onQueryChange() : refreshDataFetch()
        }
      />
    </div>
  );
};

export default DataTable;
